<template>
  <v-app id="cigre">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Prijava u CIGRE aplikaciju</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="formdata.username"
                    prepend-icon="person"
                    name="login"
                    label="Korisničko ime"
                    type="text"
                    @keyup.enter="login"
                  ></v-text-field>
                  <v-text-field
                    v-model="formdata.password"
                    prepend-icon="lock"
                    name="password"
                    label="Lozinka"
                    type="password"
                    @keyup.enter="login"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="login">Prijava</v-btn>
              </v-card-actions>
            </v-card>
            <v-alert v-model="loginfailed" dismissible type="error">
              Pogrešno korisničko ime i/ili lozinka
            </v-alert>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    formdata: {
      username: "",
      password: ""
    },
    loginfailed: false
  }),

  methods: {
    login() {
      var me = this;
      me.loginfailed = false;
      this.$http
        .post("auth/login.php", this.formdata)
        .then(function(response) {
          if (response.data.valid === "true") {
            // login successfull
            me.$store.dispatch("login");
          } else {
            // login failed
            me.loginfailed = true;
          }
        })
        .catch(function() {
          // login failed
          me.loginfailed = true;
        });
    }
  }
};
</script>
